angular.module('fingerink')
    .service('signModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'signaturemodal',
                controller: 'signModalCtrl',
                templateUrl: 'modals/signModal.tpl.html'
            });

            return this.modalInstance;
        };

    })
    .controller('signModalCtrl', function signModalCtrl($rootScope, $scope, $uibModalInstance, $window, $timeout) {

        function cortar() {
            var canvas = angular.element(document.querySelector('canvas'));
            var croppedCanvas = document.createElement('canvas'),
                croppedCtx = croppedCanvas.getContext('2d');

            croppedCanvas.width = canvas[0].width;
            croppedCanvas.height = canvas[0].height;
            croppedCtx.drawImage(canvas[0], 0, 0);

            // Next do the actual cropping
            var w = croppedCanvas.width,
                h = croppedCanvas.height,
                pix = {
                    x: [],
                    y: []
                },
                imageData = croppedCtx.getImageData(0, 0, croppedCanvas.width, croppedCanvas.height),
                x, y, index;

            for (y = 0; y < h; y++) {
                for (x = 0; x < w; x++) {
                    index = (y * w + x) * 4;
                    if (imageData.data[index + 3] > 0) {
                        pix.x.push(x);
                        pix.y.push(y);

                    }
                }
            }
            pix.x.sort(function (a, b) {
                return a - b;
            });
            pix.y.sort(function (a, b) {
                return a - b;
            });
            var n = pix.x.length - 1;

            w = pix.x[n] - pix.x[0];
            h = pix.y[n] - pix.y[0];
            var cut = croppedCtx.getImageData(pix.x[0], pix.y[0], w, h);

            croppedCanvas.width = w;
            croppedCanvas.height = h;
            croppedCtx.putImageData(cut, 0, 0);

            return croppedCanvas.toDataURL();

        }
        $scope.ok = function () {
            $uibModalInstance.close(cortar());
        };

        $scope.close = function () {
            $uibModalInstance.close();
        };

        $scope.mostrar = false;

        function calcular() {
            var WinHeight = window.innerHeight || Math.max(document.documentElement.clientHeight, document.body.clientHeight);
            var WinWidth = window.innerWidth || Math.max(document.documentElement.clientWidth, document.body.clientWidth);

            $scope.w = Math.min(1000, WinWidth - 40);
            $scope.h = Math.min(500, WinHeight - 140);
            $timeout(function () {
                $scope.mostrar = true;
                $scope.$digest();
            }, 100);
        }
        calcular();



        angular.element($window).bind('resize', function () {
            $timeout(function () {
                $scope.mostrar = false;
                calcular();
            }, 100);

        });
    });
